<template>
<div class="h5">
    <div class="image">
        <img src="../../assets/images/h5/scan_success.png" alt="">
    </div>
    <div class="title">
        快速创建试用小程序
    </div>
    <div class="desc">
        创建后你的微信号将被设置为"{{miniAppName}}的体验小程序"小程序的管理员
    </div>
    <div class="btns" v-show="this.openId">
        <el-button v-show="!authorizeUrl" type="success" @click="toCreate">立即创建小程序</el-button>
        <div style="height: 20px"></div>
        <div style="height: 20px"></div>
        <a v-show="authorizeUrl" class="alink" :href="authorizeUrl">立即授权</a>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
            userId: '',
            token: '',
            tenantId: '',
            wxAppId: '',
            openId: '',
            miniAppName: '',
            authorizeUrl: '',
            requestId: ''
        };
    },
    mounted () {
        // 如果连接中有微信返回的 code，则用此 code 调用后端接口，向微信服务器请求用户信息
        // 如果不是从微信重定向过来的，没有带着微信的 code，则直接进入首页
        if (this.$route.query.code) {
            this.tenantId = this.$route.query.tenantId;
            this.getOpenId();
        } else {
            this.$message({
                message: '缺少参数',
                showClose: true, offset: 200, type: 'error'
            });
        }
    },
    methods: {
        getOpenId: async function () {
            this.miniAppName = this.$route.query.miniAppName;
            this.wxAppId = this.$route.query.wxAppId; // 'wxb9bfd70ab1557e2e';
            this.userId = this.$route.query.userId;
            this.token = this.$route.query.token;
            let code = this.$route.query.code;
            // 获取授权，取得用户的微信id
            let authPageBaseUri = '/wx/mp/' + this.wxAppId + '/getAccessToken?code=' + code + "&tenantId=" + this.tenantId;
            await this.apis.get(authPageBaseUri)
                .then((res) => {
                    if (res.code === 1) {
                        this.openId = res.data.openId;
                        this.getExistApp()
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 200, type: 'error'
                        });
                    }
                }).catch((err) => {
                    this.$message({
                        message: err,
                        showClose: true, offset: 200, type: 'error'
                    });
                    console.log(err)
                });
        },
        getExistApp: function () {
            let data = {
                userId: this.userId,
                openId: this.openId,
                tenantId: this.tenantId
            }
            let extHeader = {
                'Authorization': `Bearer ${this.token}`,
                'tenantId': this.tenantId
            }
            this.apis.get('/wx/open/app/getByOrgId', data, false, extHeader)
                .then((res) => {
                    if (res.code === 1) {
                        if (res.data && res.data.authorizeUrl) {
                            this.authorizeUrl = res.data.authorizeUrl;
                        }
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toCreate: function () {
            let data = {
                userId: this.userId,
                openId: this.openId,
                miniAppName: this.miniAppName,
                tenantId: this.tenantId
            }
            let extHeader = {
                'Authorization': `Bearer ${this.token}`,
                'tenantId': this.tenantId
            }
            this.apis.postForm('wx/open/fastregbeta/fastRegister', data, false, extHeader)
                .then((res) => {
                    if (res.code === 1) {
                        this.authorizeUrl = res.data.authorizeUrl;
                    } else {
                        this.requestId = ''
                        this.$message({
                            message: res.msg,
                            showClose: true,
                            offset: 200,
                            type: 'error'
                        });
                    }
                }).catch((err) => {
                    console.log(err)
                });
        },
        toClose: function () {
        }
    }
};
</script>

<style scoped lang="scss">
.h5 {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    align-items: center;
    .img {
        width: 100px;
        height: auto;
    }
    .title {
        font-size: 20px;
        font-weight: bold;
        padding: 8px;
        color: #333333;
    }
    .desc {
        font-size: 16px;
        font-weight: bold;
        padding: 8px 20px;
        color: #666;
    }
    .btns {
        margin: 20px;
        display: flex;
        flex-direction: column;
    }
    .alink {
        margin: 10px;
        padding: 0px 10px;
        width: 130px;
        height: 32px;
        background: #0BBA9F;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32px;
        text-align: center;
    }
}
</style>
